import React from "react";
import Grid from "@mui/material/Grid";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export const AlertConsultarPago = () => {
  return (
    <Grid spacing={2} span={12} className="mt-3 mb-1" sx={{}}>
      <Alert severity="warning">
        <AlertTitle sx={{ fontSize: 25 }}>¡Atencion!</AlertTitle>
        <p style={{ fontSize: 20 }}>
          Pago no acreditado, favor de validar con <strong>Recaudación</strong>.
        </p>
      </Alert>
    </Grid>
  );
};
