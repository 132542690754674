import {
  PAGO_LINEA,
  ENVIO_CONCEPTOS,
  ENVIO_TP_DIVERSOS,
  ENVIO_DATOS_TABLA,
  ACTUALIZAR_DATOS_TABLA,
  ELIMINAR_DATOS_TABLA,
  ADD_DATOS_TABLA,
  CURRENT_USER,
  USER_DATA,
  ENVIO_AREAS_TABLA,
  ELEMENTO_ELIMINADO,
  CANTIDAD,
} from "../../types";

const reducer = (state, action) => {
  switch (action.type) {
    case PAGO_LINEA:
      return {
        ...state,
        pago_linea: action.payload,
      };
    case CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case ENVIO_CONCEPTOS:
      return {
        ...state,
        envio_conceptos: action.payload,
      };
    case ENVIO_TP_DIVERSOS:
      return {
        ...state,
        TP_Diverso: action.payload,
      };

    case ENVIO_DATOS_TABLA:
      return {
        ...state,
        Datos_Tabla_admin: action.payload,
      };
    case ADD_DATOS_TABLA:
      const nuevoarray = state.Datos_Tabla_admin;
      nuevoarray.push(action.payload);
      return {
        ...state,
        // Datos_Tabla_admin: action.payload.reverse()
        Datos_Tabla_admin: nuevoarray,
      };
    case ACTUALIZAR_DATOS_TABLA:
      //logica para actualizar aqui
      return {
        ...state,
        Datos_Tabla_admin: state.Datos_Tabla_admin.map((dato) =>
          dato.id === action.payload.id ? (dato = action.payload) : dato
        ),
      };

    case USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case ELIMINAR_DATOS_TABLA:
      return {
        ...state,
        Datos_Tabla_admin: state.Datos_Tabla_admin.map((dato) =>
          dato.id === action.payload.id ? (dato = action.payload) : dato
        ),
        // Datos_Tabla_admin: action.payload,
      };
    case ENVIO_AREAS_TABLA:
      return {
        ...state,
        Datos_Tabla_areas: action.payload,
      };
    case ELEMENTO_ELIMINADO:
      return {
        ...state,
        elemento_eliminado: action.payload,
      };
    case CANTIDAD:
      return {
        ...state,
        cantidad_aumentada: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
