import React, { useReducer, useState } from "react";
import CapturaContext from "./capturaContext";
import CapturaReducer from "./capturaReducer";

import {
  PAGO_LINEA,
  ENVIO_CONCEPTOS,
  ENVIO_TP_DIVERSOS,
  ENVIO_DATOS_TABLA,
  ACTUALIZAR_DATOS_TABLA,
  ELIMINAR_DATOS_TABLA,
  ADD_DATOS_TABLA,
  CURRENT_USER,
  USER_DATA,
  ENVIO_AREAS_TABLA,
  ELEMENTO_ELIMINADO,
  CANTIDAD,
} from "../../types";

const CapturaState = ({ children }) => {
  let initialState = {
    pago_linea: {},
    envio_conceptos: [{ CANTIDAD: 0, IMPORTE: 0 }],
    elemento_eliminado: null,
    cantidad_aumentada: 1,
    TP_Diverso: null,
    Datos_Tabla_admin: [],
    currentUser: null,
    userData: {},
    Datos_Tabla_areas: [],
  };
  const [title, setTitle] = useState("");
  const [concept, setConcept] = useState("");
  const [idCategoria, setIdCategoria] = useState("");
  const [state, dispatch] = useReducer(CapturaReducer, initialState);

  const envioPagoLinea = (valor) => {
    dispatch({
      type: PAGO_LINEA,
      payload: valor,
    });
  };
  const envioConceptos = (valor) => {
    dispatch({
      type: ENVIO_CONCEPTOS,
      payload: valor,
    });
  };

  const setTPDiversos = (valor) => {
    dispatch({
      type: ENVIO_TP_DIVERSOS,
      payload: valor,
    });
  };

  const setDatosTabla = (valor) => {
    dispatch({
      type: ENVIO_DATOS_TABLA,
      payload: valor,
    });
  };

  const editDatosTabla = (valor) => {
    dispatch({
      type: ACTUALIZAR_DATOS_TABLA,
      payload: valor,
    });
  };

  const deletDatosTabla = (valor) => {
    dispatch({
      type: ELIMINAR_DATOS_TABLA,
      payload: valor,
    });
  };

  const addDatosTabla = (valor) => {
    dispatch({
      type: ADD_DATOS_TABLA,
      payload: valor,
    });
  };

  const setCurrentUser = (valor) => {
    dispatch({
      type: CURRENT_USER,
      payload: valor,
    });
  };
  const setUserData = (valor) => {
    dispatch({
      type: USER_DATA,
      payload: valor,
    });
  };

  const setAreas = (valor) => {
    dispatch({
      type: ENVIO_AREAS_TABLA,
      payload: valor,
    });
  };

  const setElementDelete = (element) => {
    dispatch({
      type: ELEMENTO_ELIMINADO,
      payload: element,
    });
  };

  const incrementarCantidad = (element) => {
    dispatch({
      type: CANTIDAD,
      payload: element,
    });
  };

  return (
    <CapturaContext.Provider
      value={{
        setDatosTabla,
        editDatosTabla,
        deletDatosTabla,
        addDatosTabla,
        envioPagoLinea,
        setTPDiversos,
        idCategoria,
        setIdCategoria,
        pago_linea: state.pago_linea,
        envioConceptos,
        envio_conceptos: state.envio_conceptos,
        title,
        setTitle,
        concept,
        setConcept,
        TPDiverso: state.TP_Diverso,
        AdminTableData: state.Datos_Tabla_admin,
        currentUser: state.currentUser,
        setCurrentUser,
        setUserData,
        userData: state.userData,
        setAreas,
        AdminTableAreas: state.Datos_Tabla_areas,
        elemento_eliminado: state.elemento_eliminado,
        setElementDelete,
        incrementarCantidad,
        cantidad_aumentada: state.cantidad_aumentada,
      }}
    >
      {children}
    </CapturaContext.Provider>
  );
};

export default CapturaState;
