import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const color = "#6f1d46";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: color,
  "&:hover": {
    backgroundColor: color,
  },
}));

const headers = [
  "Línea de Captura",
  "Importe",
  "Número de autorización",
  "Estatus",
  "Acción",
];

export const TableConsultarPago = ({ state, reprint }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                fontSize: "20px",
                backgroundColor: color,
                textAlign: "center",
              },
            }}
          >
            {headers.map((item) => (
              <TableCell key={item} sx={{ color: "white" }}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
                fontSize: "20px",
                height: "60px",
                textAlign: "left",
                backgroundColor: "#ecf0f1",
              },
            }}
          >
            <TableCell component="th" scope="row">
              {state.linCaptura}
            </TableCell>
            <TableCell component="th" scope="row">
              {state.importe}
            </TableCell>
            <TableCell component="th" scope="row">
              {state.auth}
            </TableCell>
            <TableCell component="th" scope="row">
              {state.status}
            </TableCell>
            <TableCell component="th" scope="row">
              <ColorButton
                variant="contained"
                onClick={() => reprint(state.operation)}
              >
                Reimpresión
              </ColorButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
