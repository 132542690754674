export const PAGO_LINEA = "PAGO_LINEA";
export const ENVIO_CONCEPTOS = "ENVIO_CONCEPTOS";
export const BASEURL = "http://localhost:3000/";
export const ENVIO_TP_DIVERSOS = "ENVIO_TP_DIVERSOS";
export const ENVIO_DATOS_TABLA = "ENVIO_DATOS_TABLA";
export const ACTUALIZAR_DATOS_TABLA = "ACTUALIZAR_DATOS_TABLA";
export const ELIMINAR_DATOS_TABLA = "ELIMINAR_DATOS_TABLA";
export const ADD_DATOS_TABLA = "ADD_DATOS_TABLA";
export const CURRENT_USER = "CURRENT_USER";
export const USER_DATA = "USER_DATA";
export const ENVIO_AREAS_TABLA = "ENVIO_AREAS_TABLA";
export const ELEMENTO_ELIMINADO = "ELEMENTO_ELIMINADO";
export const CANTIDAD = "CANTIDAD";
