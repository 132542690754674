import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { Card, TextField } from "@mui/material";
import GifLoader from "../../components/ui/gifLoader";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import swal from "sweetalert";
import { usePINPAD } from "../../hooks/usePINPAD";
import {
  TableConsultarPago,
  CalendarConsultarPago,
  AlertConsultarPago,
} from "../../components/pinpad";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: "#6f1d46",
  "&:hover": {
    backgroundColor: "#6f1d46",
  },
}));

export const ReimpresionPago = () => {
  const [loaderPinpad, setLoaderPinpad] = useState(false);
  const [form, setForm] = useState({
    numReferencia: "",
    fecha: "",
  });
  const [state, setState] = useState({
    showTable: false,
    showAlert: false,
    linCaptura: "",
    importe: "",
    auth: "",
    status: "",
    operation: "",
  });
  const { urlPINPAD, consultarPago, rePrintVoucher } = usePINPAD({
    setLoaderPinpad,
    setState,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    const regex = /^[0-9\b]+$/;

    if (value === "" || regex.test(value)) {
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
    }
  };

  const consultarReferencia = () => {
    const allFieldsFilled = Object.values(form).every((value) => value !== "");
    if (allFieldsFilled) {
      consultarPago(form);
    } else {
      swal({
        title: "Error",
        text: "Por favor, complete todos los campos",
        icon: "error",
        buttons: false,
        timer: 2000,
      });
      setLoaderPinpad(false);
    }
  };

  return (
    <>
      {loaderPinpad && <GifLoader />}
      <iframe
        id="iframeTo"
        src={urlPINPAD}
        title="Pinpad"
        width="0"
        height="0"
        sx={{
          display: "none",
        }}
        allow="serial;geolocation;"
      ></iframe>
      <Grid container justifyContent="center" className="my-5">
        <Card
          style={{
            padding: 30,
            marginBottom: 50,
            width: "80%",
          }}
          variant="outlined"
        >
          <Grid spacing={2} span={12} className="mb-4 text-center">
            <CalendarConsultarPago setForm={setForm} />
            <InputLabel style={{ marginRight: "130px", marginBottom: "4px" }}>
              Línea de Captura:
            </InputLabel>
            <TextField
              required
              type="text"
              name="numReferencia"
              value={form.numReferencia}
              onChange={handleChange}
              InputProps={{
                sx: {
                  borderRadius: "6px",
                  height: "40px",
                  width: "300px",
                  marginRight: "10px",
                },
              }}
            />
            <ColorButton variant="contained" onClick={consultarReferencia}>
              Consultar
            </ColorButton>
          </Grid>
          {state.showTable && (
            <TableConsultarPago state={state} reprint={rePrintVoucher} />
          )}
          {state.showAlert && <AlertConsultarPago />}
        </Card>
      </Grid>
    </>
  );
};
