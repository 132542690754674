import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/es";

export const CalendarConsultarPago = ({ setForm }) => {
  const [date, setDate] = useState("");

  useEffect(() => {
    const fecha = new Date();
    let dia = fecha.getDate();
    let mes = fecha.getMonth() + 1;
    const año = fecha.getFullYear();

    if (dia < 10) dia = "0" + dia;
    if (mes < 10) mes = "0" + mes;
    setForm((prevState) => ({
      ...prevState,
      fecha: `${dia}/${mes}/${año}`,
    }));
    setDate(`${año}-${mes}-${dia}`);
  }, []);

  const handleChangeDate = (date) => {
    let dia = date.$D;
    let mes = date.$M + 1;

    if (dia < 10) dia = "0" + dia;
    if (mes < 10) mes = "0" + mes;

    setForm((prevState) => ({
      ...prevState,
      fecha: `${dia}/${mes}/${date.$y}`,
    }));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <DemoContainer
        components={["DatePicker"]}
        sx={{
          marginBottom: 3,
          marginX: "auto",
          maxWidth: 300,
        }}
      >
        <DatePicker
          label="Seleccionar Fecha de Pago"
          value={dayjs(date)}
          onChange={handleChangeDate}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};
